 <template>
    <div>
        <a-page-header title="shopify" class="header_title"/>
        <div class="box_content">
            <div style="text-align: right;margin-bottom: 15px;">
                <img src="/img/shopify.png" style="height:30px;"/>
                <a-button @click="showModal()" type="danger">
                    {{$t('shopify.btn_add')}}
                </a-button>
            </div>
            <div class="list">

                <div class="list-box">
                    <div class="list-item">
                    <div class="list-item-t1">
                        <img src="/img/network.png" style="height:30px;"/>
                        <img src="/img/spot.png" style="height:30px;float:right;"/>
                    </div>
                    <p>domain name</p>
                    <p class="list-item-t2">poaea</p>
                    <p>Protection scheme: None</p>
                    <div class="list-item-t3">24/1/2022expire &nbsp;
                    <a href="#" class="list-item-t4">Renew now</a>
                    </div>
                </div>
                </div>

                <div class="list-box">
                <div class="list-item">
                    <div class="list-item-t1">
                        <img src="/img/network.png" style="height:30px;"/>
                        <img src="/img/spot.png" style="height:30px;float:right;"/>
                    </div>
                    <p>domain name</p>
                    <p class="list-item-t2">frint</p>
                    <p>Protection scheme: None</p>
                    <div class="list-item-t3_2">Automatic renewal 5/3/2022&nbsp;
                    <a href="#" class="list-item-t4">Renew now</a>
                    </div>
                </div>
                </div>

                <div class="list-box">
                <div class="list-item">
                    <div class="list-item-t1">
                        <img src="/img/network.png" style="height:30px;"/>
                        <img src="/img/spot.png" style="height:30px;float:right;"/>
                    </div>
                    <p>domain name</p>
                    <p class="list-item-t2">globaltrademarksearch.com</p>
                    <p>Protection scheme: None</p>
                    <div class="list-item-t3"><a href="#" class="list-item-t4">Not set</a>
                    </div>
                </div>
                </div>

                <div class="list-box">
                <div class="list-item">
                    <div class="list-item-t1">
                        <img src="/img/network.png" style="height:30px;"/>
                        <img src="/img/spot.png" style="height:30px;float:right;"/>
                    </div>
                    <p>domain name</p>
                    <p class="list-item-t2">globaltrademarksearch.com</p>
                    <p>Protection scheme: None</p>
                    <div class="list-item-t3"><a href="#" class="list-item-t4">Not set</a>
                    </div>
                </div>
                </div>

                <div class="list-box">
                <div class="list-item">
                    <div class="list-item-t1">
                        <img src="/img/network.png" style="height:30px;"/>
                        <img src="/img/spot.png" style="height:30px;float:right;"/>
                    </div>
                    <p>domain name</p>
                    <p class="list-item-t2">hisweetie.co</p>
                    <p>Protection scheme: None</p>
                    <div class="list-item-t3"><a href="#" class="list-item-t4">Not set</a>
                    </div>
                </div>
                </div>

                <div class="list-box">
                <div class="list-item">
                    <div class="list-item-t1">
                        <img src="/img/network.png" style="height:30px;"/>
                        <img src="/img/spot.png" style="height:30px;float:right;"/>
                    </div>
                    <p>domain name</p>
                    <p class="list-item-t2">jene.com</p>
                    <p>Protection scheme: None</p>
                    <div class="list-item-t3"><a href="#" class="list-item-t4">Not set</a>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <a-modal v-model="visible" title="Add Shopify authorization" on-ok="handleOk">
            <template slot="footer">
                <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19}">
                    <a-form-item label="Store alias">
                        <a-input
                            v-decorator="[
                            'str1',
                            { rules: [{ required: true, message: 'Please input!' }] },
                            ]"
                            placeholder="Please input!"
                        >
                        </a-input>
                    </a-form-item>  
                    <a-form-item label="Store website">
                        <a-input
                        v-decorator="[
                            'str2',
                            { rules: [{ required: true, message: 'Please input!' }] },
                        ]"
                        placeholder=""
                        addon-before="https://" addon-after=".myshopify.com"
                        >
                        </a-input>
                    </a-form-item>
                    <a-form-item :label-col="5" :wrapper-col="19">
                        <a-button key="submit" type="danger" @click="handleOk">
                        Submit
                        </a-button>
                    </a-form-item>

                </a-form>
            </template>
        </a-modal>

    </div>
</template>

<script>
export default {
    name: 'NodeprojectSetting',
    components: {  },
    directives: {  },
    data() {
        return {
            form: this.$form.createForm(this, { name: 'coordinated' }),
            visible: false,
        };
    },
    mounted() {
        
    },
    methods: {
        handleOk(e) {
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.visible = false;
                    this.$message.info("under review!");
                
                }
            });
        },
        addProductBtn() {
            
        },
        showModal() {
            this.visible = true;
        },
        handleCancel(e) {
            this.visible = false;
        },
    },
};
</script>

<style  scoped>
.ant-modal-body{
    padding: 0;
}
.list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.list-box{
    width:33.33%;padding: 5px;
}
.list-item{
    width:100%;padding: 10px;border: 1px solid #e7e7ec;
}
.list-item-t1{
    min-width:250px;margin-bottom: 30px;
}
.list-item-t2{
    font-size: 17px;
    font-weight: 600;
    color: black;
}
.list-item-t3{
    background-color: rgb(255,238,167);
    text-align: center;
    color: black;
    padding: 7px;
}
.list-item-t3_2{
    background-color: rgb(233,233,237);
    text-align: center;
    color: black;
    padding: 7px;
}
.list-item-t4{
    color: black;
    font-weight: 600;
}
</style>